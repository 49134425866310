import Helmet from "react-helmet"
import React from "react"
import AmoTyp from "@tightrope/amotyp"
import Data from "./data/data.json"
import SiteData from "branding/site-metadata.json"
import Styles from "./css/amotype.module.scss"
import FooterlinksSingle from "@tightrope/footerlinks/footerlinks_single.js"



export default function ThankYouAmo() {
  return(
    <>
    <Helmet>
     <style type="text/css">{`
    }
    `}
    </style>
    <title>Thank you for downloading</title>
    </Helmet>

    <section className={Styles.amoContainer}>
      <div className={Styles.tyTop}>
      <img className={Styles.aUp} src="/img/arrow-up.png" alt="Easy Print"></img>
        <img className={Styles.mainLogo} src="/img/easyprint-logo.png" alt="Easy Print"></img>
        <h1>Thank you for installing Easy Print</h1>
      <div className={Styles.aCard}>
                   <h3>How to Access Easy Print</h3>
                   <ul>
                    <li>Look for the Easy Print Icon <img src="/img/ico.png" alt="Easy Print"></img></li>
                    <li><span>Can't find it?</span> Click the <img src="/img/puzzle.jpg" alt="Easy Print"></img>icon to expand the list of your extensions</li>
                   </ul>
               </div>
      </div>
      <div className={Styles.tyBottom}>
      <h2>Edit printing options in Clean View</h2>
      <div>
      <p>Print with:</p>
      <ul>
      <li>No Advertisements</li>
      <li>No Images (save on ink!)</li>
      <li>Edit font size for readability</li>
      <li>Hightlight important text</li>
      </ul>
      <p>Start now by clicking the extension icon!</p>
      </div>
      <div>
        <img src="/img/print-options.jpg" alt="Easy Print"></img>
      </div>
      </div>
      <FooterlinksSingle></FooterlinksSingle>
    </section>
    </>
  )
}
